import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import useLanguage from '@/hooks/useLanguage'

const RoutePrivate = ({ children, ...props }) => {
  const { authenticatedUser } = useSelector(state => state.authentication)
  const [_, setLanguage] = useLanguage()

  useEffect(() => {
    if (authenticatedUser && authenticatedUser.language) {
      setLanguage(authenticatedUser.language)
    }
  }, [authenticatedUser])

  return (
    <Route
      {...props}
      render={({ location }) =>
        authenticatedUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default RoutePrivate
