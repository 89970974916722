export const STATES = [
  {
    name: 'Acre',
    code: 'BR-AC'
  },
  {
    name: 'Alagoas',
    code: 'BR-AL'
  },
  {
    name: 'Amapá',
    code: 'BR-AP'
  },
  {
    name: 'Amazonas',
    code: 'BR-AM'
  },
  {
    name: 'Bahia',
    code: 'BR-BA'
  },
  {
    name: 'Ceará',
    code: 'BR-CE'
  },
  {
    name: 'Distrito Federal',
    code: 'BR-DF'
  },
  {
    name: 'Espírito Santo',
    code: 'BR-ES'
  },
  {
    name: 'Goiás',
    code: 'BR-GO'
  },
  {
    name: 'Maranhão',
    code: 'BR-MA'
  },
  {
    name: 'Mato Grosso',
    code: 'BR-MT'
  },
  {
    name: 'Mato Grosso do Sul',
    code: 'BR-MS'
  },
  {
    name: 'Minas Gerais',
    code: 'BR-MG'
  },
  {
    name: 'Pará',
    code: 'BR-PA'
  },
  {
    name: 'Paraíba',
    code: 'BR-PB'
  },
  {
    name: 'Paraná',
    code: 'BR-PR'
  },
  {
    name: 'Pernambuco',
    code: 'BR-PE'
  },
  {
    name: 'Piauí',
    code: 'BR-PI'
  },
  {
    name: 'Rio de Janeiro',
    code: 'BR-RJ'
  },
  {
    name: 'Rio Grande do Norte',
    code: 'BR-RN'
  },
  {
    name: 'Rio Grande do Sul',
    code: 'BR-RS'
  },
  {
    name: 'Rondônia',
    code: 'BR-RO'
  },
  {
    name: 'Roraima',
    code: 'BR-RR'
  },
  {
    name: 'Santa Catarina',
    code: 'BR-SC'
  },
  {
    name: 'São Paulo',
    code: 'BR-SP'
  },
  {
    name: 'Sergipe',
    code: 'BR-SE'
  },
  {
    name: 'Tocantins',
    code: 'BR-TO'
  }
]
