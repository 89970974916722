import styled from 'styled-components'
import FWModal from '@/components/UIKits/Modal'

export const Modal = styled(FWModal)`
  text-align: center !important;
`

export const Image = styled.img`
  width: auto;
  max-width: 100%;
  height: 15rem;
  margin: auto auto 1.25rem;

  @media screen and (max-width: 600px) {
    height: 10rem;
  }
`

export const ModalMain = styled.main`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
`

export const ModalTitle = styled.h6`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`

export const ModalContent = styled.div`
  white-space: pre-line;
`

export const ModalFooter = styled.footer`

  button:first-child {
    padding: 0 5rem;
    margin-bottom: 0.25rem;
  }
`
