import axios from 'axios'
import { GET, POST, DELETE } from '@/utils/axios'

export const saveBusinessEmail = async formData =>
  await axios.post(
    'https://business-api.flavorwiki.com/api/marketing/submit-join-mail',
    formData
  )

export const signIn = async formData => await POST('/login', formData)

export const signupWithEmail = async formData =>
  await POST('/signup-with-email', formData)

export const signupWithSocial = async formData =>
  await POST('/signup-with-social', formData)

export const forgotPassword = async formData =>
  await POST('/forgotPassword', formData)

export const resetPassword = async formData =>
  await POST('/resetPassword', formData)

export const resendVerificationEmail = async formData =>
  await POST('/resend-verification-email', formData)

export const checkUserEmail = async formData =>
  await POST('/check-user-email', formData)

export const socialLogin = async formData =>
  await POST('/social-login', formData)

export const impersonate = async formData =>
  await POST('/verify-impersonation-token', formData)

export const verifyEmail = async formData =>
  await POST('/verify-email', formData)

export const getProfile = async () =>
  await GET('/me', { useAuthorization: true })

export const updateProfile = async formData =>
  await POST('/update-profile', formData, { useAuthorization: true })

export const updatePassword = async formData =>
  await POST('/update-password', formData, { useAuthorization: true })

export const deleteAccount = async formData =>
  await DELETE(`/delete/${formData.email}`, { useAuthorization: true })

export const twoFACheckIfUserAuthenticated = async user =>
  await GET(`/twoFA-check?userId=${user.id}`)

export const getTwofaMobile = async formData =>
  await GET(
    `/getTwofaMobile?phonenumber=${formData.mobileNumber2FA}&channel=${formData.channel}&userId=${formData.userId}&mobileNumber=${formData.mobileNumber}`
  )

export const verifyTwofaMobile = async formData =>
  await GET(
    `/verifyTwofaMobile?phonenumber=${formData.mobileNumber2FA}&code=${formData.twoFACode}&userId=${formData.userId}&mobileNumber=${formData.mobileNumber}`
  )
