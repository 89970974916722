import {
  COUNTRY_PHONE_CODES,
  CURRENCY_PREFIXES,
  CURRENCY_SUFFIXES
} from '@/utils/constants'

export const exchange = (code = 'US', amount = 0) => {
  const country = COUNTRY_PHONE_CODES.find(c => c.code === code)
  const currency = (country && country.currency) || 'USD'
  const currencyPrefix = CURRENCY_PREFIXES[currency] || ''
  const currencySuffix = CURRENCY_SUFFIXES[currency] || ''

  return `${currencyPrefix}${amount}${currencySuffix}`
}

const action = {
  exchange
}

export default action
