import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { HASHMAP_LANGUAGE_CLIENT, MOMENT_LOCALE } from '@/utils/constants'

const useLanguage = () => {
  const { i18n } = useTranslation()

  const setLanguage = (value = 'en') => {
    const language = HASHMAP_LANGUAGE_CLIENT[value]

    if (language && i18n.language !== language) {
      i18n.changeLanguage(language)

      window.recaptchaOptions = {
        lang: language,
        useRecaptchaNet: true,
        removeOnUnmount: true
      }
    }

    if (MOMENT_LOCALE[value]) {
      moment.locale(MOMENT_LOCALE[value])
    }
  }

  if (i18n.language === 'en-US') {
    setLanguage()
  }

  return [i18n.language || 'en', setLanguage]
}

export default useLanguage
