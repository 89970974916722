import React from 'react'
import Button from '@/components/UIKits/Button'
import {
  Modal,
  Image,
  ModalMain,
  ModalTitle,
  ModalContent,
  ModalFooter
} from './styles'

const ModalWithContent = ({
  visible,
  setVisible,
  closable = false,
  imageSrc,
  title,
  content,
  okText,
  onOk,
  cancelText,
  onCancel
}) => {
  const onClickCancel = () => {
    if (onCancel) {
      onCancel()
    }

    if (!onCancel) {
      setVisible(false)
    }
  }

  const onClose = () => {
    if (closable) {
      setVisible(false)
    }
  }

  return (
    <Modal
      visible={visible}
      centered
      closable={closable}
      footer={null}
      onCancel={onClose}
    >
      {imageSrc && <Image src={imageSrc} />}

      <ModalMain>
        {title && <ModalTitle>{title}</ModalTitle>}

        <ModalContent dangerouslySetInnerHTML={{ __html: content }} />
      </ModalMain>

      <ModalFooter>
        <Button type='primary' size='large' onClick={onOk}>
          {okText}
        </Button>

        {cancelText && (
          <Button type='link' size='large' onClick={onClickCancel}>
            {cancelText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default ModalWithContent
