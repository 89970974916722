export const STATES = [
  { name: 'Alabama', code: 'US-AL' },
  { name: 'Alaska', code: 'US-AK' },
  { name: 'American Samoa', code: 'US-AS' },
  { name: 'Arizona', code: 'US-AZ' },
  { name: 'Arkansas', code: 'US-AR' },
  { name: 'California', code: 'US-CA' },
  { name: 'Colorado', code: 'US-CO' },
  { name: 'Connecticut', code: 'US-CT' },
  { name: 'Delaware', code: 'US-DE' },
  { name: 'District Of Columbia', code: 'US-DC' },
  { name: 'Federated States Of Micronesia', code: 'US-FM' },
  { name: 'Florida', code: 'US-FL' },
  { name: 'Georgia', code: 'US-GA' },
  { name: 'Guam', code: 'US-GU' },
  { name: 'Hawaii', code: 'US-HI' },
  { name: 'Idaho', code: 'US-ID' },
  { name: 'Illinois', code: 'US-IL' },
  { name: 'Indiana', code: 'US-IN' },
  { name: 'Iowa', code: 'US-IA' },
  { name: 'Kansas', code: 'US-KS' },
  { name: 'Kentucky', code: 'US-KY' },
  { name: 'Louisiana', code: 'US-LA' },
  { name: 'Maine', code: 'US-ME' },
  { name: 'Marshall Islands', code: 'US-MH' },
  { name: 'Maryland', code: 'US-MD' },
  { name: 'Massachusetts', code: 'US-MA' },
  { name: 'Michigan', code: 'US-MI' },
  { name: 'Minnesota', code: 'US-MN' },
  { name: 'Mississippi', code: 'US-MS' },
  { name: 'Missouri', code: 'US-MO' },
  { name: 'Montana', code: 'US-MT' },
  { name: 'Nebraska', code: 'US-NE' },
  { name: 'Nevada', code: 'US-NV' },
  { name: 'New Hampshire', code: 'US-NH' },
  { name: 'New Jersey', code: 'US-NJ' },
  { name: 'New Mexico', code: 'US-NM' },
  { name: 'New York', code: 'US-NY' },
  { name: 'North Carolina', code: 'US-NC' },
  { name: 'North Dakota', code: 'US-ND' },
  { name: 'Northern Mariana Islands', code: 'US-MP' },
  { name: 'Ohio', code: 'US-OH' },
  { name: 'Oklahoma', code: 'US-OK' },
  { name: 'Oregon', code: 'US-OR' },
  { name: 'Palau', code: 'US-PW' },
  { name: 'Pennsylvania', code: 'US-PA' },
  { name: 'Puerto Rico', code: 'US-PR' },
  { name: 'Rhode Island', code: 'US-RI' },
  { name: 'South Carolina', code: 'US-SC' },
  { name: 'South Dakota', code: 'US-SD' },
  { name: 'Tennessee', code: 'US-TN' },
  { name: 'Texas', code: 'US-TX' },
  { name: 'Utah', code: 'US-UT' },
  { name: 'Vermont', code: 'US-VT' },
  { name: 'Virgin Islands', code: 'US-VI' },
  { name: 'Virginia', code: 'US-VA' },
  { name: 'Washington', code: 'US-WA' },
  { name: 'West Virginia', code: 'US-WV' },
  { name: 'Wisconsin', code: 'US-WI' },
  { name: 'Wyoming', code: 'US-WY' }
]
