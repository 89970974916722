export const STATES = [
  {
    name: "Āzād Kashmīr",
    code: "PK-JK"
  },
  {
    name: "Balōchistān",
    code: "PK-BA"
  },
  {
    name: "Gilgit-Baltistān",
    code: "PK-GB"
  },
  {
    name: "Islāmābād",
    code: "PK-IS"
  },
  {
    name: "Khaībar Pakhtūnkhwās",
    code: "PK-KP"
  },
  {
    name: "Punjāb",
    code: "PK-PB"
  },
  {
    name: "Sindh",
    code: "PK-SD"
  },
  {
    name: "Federally Administered Tribal Areas",
    code: "PK-TA"
  }
]