import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_THEME } from '@/utils/constants'

export const slice = createSlice({
  name: 'theme',
  initialState: {
    theme: { ...DEFAULT_THEME }
  },
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload
    }
  }
})

export const { setTheme } = slice.actions

export default slice.reducer
