export const STATES = [
  {
    name: 'Avon',
    code: 'UK-AVN'
  },
  {
    name: 'Bedfordshire',
    code: 'UK-BDF'
  },
  {
    name: 'Berkshire',
    code: 'UK-BRK'
  },
  {
    name: 'Buckinghamshire',
    code: 'UK-BKM'
  },
  {
    name: 'Cambridgeshire',
    code: 'UK-CAM'
  },
  {
    name: 'Cheshire',
    code: 'UK-CHS'
  },
  {
    name: 'Cleveland',
    code: 'UK-CLV'
  },
  {
    name: 'Cornwall',
    code: 'UK-CON'
  },
  {
    name: 'Cumbria',
    code: 'UK-CMA'
  },
  {
    name: 'Derbyshire',
    code: 'UK-DBY'
  },
  {
    name: 'Devon',
    code: 'UK-DEV'
  },
  {
    name: 'Dorset',
    code: 'UK-DOR'
  },
  {
    name: 'Durham',
    code: 'UK-DUR'
  },
  {
    name: 'East Sussex',
    code: 'UK-SXE'
  },
  {
    name: 'Essex',
    code: 'UK-ESS'
  },
  {
    name: 'Gloucestershire',
    code: 'UK-GLS'
  },
  {
    name: 'Hampshire',
    code: 'UK-HAM'
  },
  {
    name: 'Herefordshire',
    code: 'UK-HEF'
  },
  {
    name: 'Hertfordshire',
    code: 'UK-HRT'
  },
  {
    name: 'Isle of Wight',
    code: 'UK-IOW'
  },
  {
    name: 'Kent',
    code: 'UK-KEN'
  },
  {
    name: 'Lancashire',
    code: 'UK-LAN'
  },
  {
    name: 'Leicestershire',
    code: 'UK-LEI'
  },
  {
    name: 'Lincolnshire',
    code: 'UK-LIN'
  },
  {
    name: 'London',
    code: 'UK-LDN'
  },
  {
    name: 'Merseyside',
    code: 'UK-MSY'
  },
  {
    name: 'Norfolk',
    code: 'UK-NFK'
  },
  {
    name: 'Northamptonshire',
    code: 'UK-NTH'
  },
  {
    name: 'Northumberland',
    code: 'UK-NBL'
  },
  {
    name: 'North Yorkshire',
    code: 'UK-NYK'
  },
  {
    name: 'Nottinghamshire',
    code: 'UK-NTT'
  },
  {
    name: 'Oxfordshire',
    code: 'UK-OXF'
  },
  {
    name: 'Rutland',
    code: 'UK-RUT'
  },
  {
    name: 'Shropshire',
    code: 'UK-SAL'
  },
  {
    name: 'Somerset',
    code: 'UK-SOM'
  },
  {
    name: 'South Yorkshire',
    code: 'UK-SYK'
  },
  {
    name: 'Staffordshire',
    code: 'UK-STS'
  },
  {
    name: 'Suffolk',
    code: 'UK-SFK'
  },
  {
    name: 'Surrey',
    code: 'UK-SRY'
  },
  {
    name: 'Tyne and Wear',
    code: 'UK-TWR'
  },
  {
    name: 'Warwickshire',
    code: 'UK-WAR'
  },
  {
    name: 'West Midlands',
    code: 'UK-WMD'
  },
  {
    name: 'West Sussex',
    code: 'UK-SXW'
  },
  {
    name: 'West Yorkshire',
    code: 'UK-WYK'
  },
  {
    name: 'Wiltshire',
    code: 'UK-WIL'
  },
  {
    name: 'Worcestershire',
    code: 'UK-WOR'
  },
  {
    name: 'Clwyd',
    code: 'UK-CWD'
  },
  {
    name: 'Dyfed',
    code: 'UK-DFD'
  },
  {
    name: 'Gwent',
    code: 'UK-GNT'
  },
  {
    name: 'Gwynedd',
    code: 'UK-GWN'
  },
  {
    name: 'Mid Glamorgan',
    code: 'UK-MGM'
  },
  {
    name: 'Powys',
    code: 'UK-POW'
  },
  {
    name: 'South Glamorgan',
    code: 'UK-SGM'
  },
  {
    name: 'West Glamorgan',
    code: 'UK-WGM'
  },
  {
    name: 'Aberdeenshire',
    code: 'UK-ABD'
  },
  {
    name: 'Angus',
    code: 'UK-ANS'
  },
  {
    name: 'Argyll',
    code: 'UK-ARL'
  },
  {
    name: 'Ayrshire',
    code: 'UK-AYR'
  },
  {
    name: 'Banffshire',
    code: 'UK-BAN'
  },
  {
    name: 'Berwickshire',
    code: 'UK-BEW'
  },
  {
    name: 'Bute',
    code: 'UK-BUT'
  },
  {
    name: 'Caithness',
    code: 'UK-CAI'
  },
  {
    name: 'Clackmannanshire',
    code: 'UK-CLK'
  },
  {
    name: 'Dumfriesshire',
    code: 'UK-DGY'
  },
  {
    name: 'Dunbartonshire',
    code: 'UK-DNB'
  },
  {
    name: 'East Lothian',
    code: 'UK-ELN'
  },
  {
    name: 'Fife',
    code: 'UK-FIF'
  },
  {
    name: 'Inverness-shire',
    code: 'UK-INV'
  },
  {
    name: 'Kincardineshire',
    code: 'UK-KCD'
  },
  {
    name: 'Kinross-shire',
    code: 'UK-KRS'
  },
  {
    name: 'Kirkcudbrightshire',
    code: 'UK-KKD'
  },
  {
    name: 'Lanarkshire',
    code: 'UK-LKS'
  },
  {
    name: 'Midlothian',
    code: 'UK-MLN'
  },
  {
    name: 'Moray',
    code: 'UK-MOR'
  },
  {
    name: 'Nairnshire',
    code: 'UK-NAI'
  },
  {
    name: 'Orkney',
    code: 'UK-OKI'
  },
  {
    name: 'Peeblesshire',
    code: 'UK-PEE'
  },
  {
    name: 'Perthshire',
    code: 'UK-PER'
  },
  {
    name: 'Renfrewshire',
    code: 'UK-RFW'
  },
  {
    name: 'Ross-shire',
    code: 'UK-ROC'
  },
  {
    name: 'Roxburghshire',
    code: 'UK-ROX'
  },
  {
    name: 'Selkirkshire',
    code: 'UK-SEL'
  },
  {
    name: 'Shetland',
    code: 'UK-SHI'
  },
  {
    name: 'Stirlingshire',
    code: 'UK-STI'
  },
  {
    name: 'Sutherland',
    code: 'UK-SUT'
  },
  {
    name: 'West Lothian',
    code: 'UK-WLN'
  },
  {
    name: 'Wigtownshire',
    code: 'UK-WIG'
  },
  {
    name: 'Antrim',
    code: 'UK-ANT'
  },
  {
    name: 'Armagh',
    code: 'UK-ARM'
  },
  {
    name: 'Down',
    code: 'UK-DOW'
  },
  {
    name: 'Fermanagh',
    code: 'UK-FER'
  },
  {
    name: 'Londonderry',
    code: 'UK-LDY'
  },
  {
    name: 'Tyrone',
    code: 'UK-TYR'
  }
]
