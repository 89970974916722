export const DEFAULT_THEME = {
  NAME: 'flavorwiki',
  APP_TITLE: 'Flavorwiki',
  ICON_URL: 'https://tools.flavorwiki.com/default.png',
  FULL_LOGO_URL:
    'https://taster-flavorwiki.s3.eu-central-1.amazonaws.com/FlavorWiki_Full_Logo.png',
  TITLE_FONT: 'Montserrat',
  TEXT_FONT: 'DM Sans',
  ALLOW_SOCIAL_NETWORK: true,
  ALLOW_TASTER: true,
  ALLOW_SHARE: true,
  TOKEN_HOURS: 24,
  PRIMARY_COLOR: '#8aba5c',
  FACEBOOK: '#4267B2',
  TWITTER: '#1f9bf0',
  SLATE_GREY: '#4e4e56',
  PALE_LIGHT_GREY: '#e4e4e5',
  LIGHT_GREY: '#cdcdd2',
  CHART_LABEL: '#7f7f7f',
  VERY_LIGHT_GREY: '#f0f0f0',
  ERROR: '#f5222d',
  WHITE: '#ffffff',
  BLACK: '#000000',
  LIGHT_BLUE: '#1890ff',
  SALMON: '#df625f',
  DARK_BACKGROUND: '#fafafa',
  ADMIN_CONTENT_BACKGROUND: '#f0f2f5',
  RED: '#e90000',
  DARKER_RED: '#d10000',
  BLUE_GREEN: '#147a73',
  SHARE: '#1a73e8',
  CARD_BORDER: '#eeeeee',
  SOCIAL_TEXT_COLOR: '#73738d',
  SOCIAL_BUTTON_COLOR: '#eaedf3'
}
