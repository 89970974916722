import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Section, SectionMain } from '../styles'

const Error404 = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <SectionMain>
        <h1>404</h1>
        <h2>{t('error.404.title')}</h2>
        <p>{t('error.404.description')}</p>
        <Link to='/'>{t('pages.verifyEmail.goToHome')}</Link>
      </SectionMain>
    </Section>
  )
}

export default Error404
