import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import * as serviceWorker from '@/serviceWorker'

import App from '@/App'
import store from '@/store'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URI,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'SecurityError: The operation is insecure.', // https://bugzilla.mozilla.org/show_bug.cgi?id=303952
    'Illegal invocation',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    `can't redefine non-configurable property "userAgent"`, // related to the get browser info - https://stackoverflow.com/questions/66033349/javascript-error-on-firefox-typeerror-cant-redefine-non-configurable-property
    'gtkMatch is null', // no info of error but error has mozilla extension meaning it is most probably an extensions error
    'Unable to find node on an unmounted component.', // Error exists in react version 17, but react 16 is fine, related to a library dependancy - https://github.com/facebook/react/issues/20131
    `Can't find variable: setIOSParameters`, // occuring only on ios safari 
    `Cannot read properties of undefined (reading 'getAttribute')`, // chrome extension error. Tries to document.getElementById() and then .getAttribute() but the element has not been loaded yet - https://stackoverflow.com/questions/32542312/uncaught-typeerror-cannot-read-property-getattribute-of-null
    'The object can not be cloned.', // related to upload photo antd - Occurs only on iphone with Chrome/Google App when uploading an image
    `undefined is not an object (evaluating '__gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost')`, // happens on chrome on ios, fairly safe to ignore - https://stackoverflow.com/questions/52605490/null-is-not-an-object-evaluating-gcrweb-form-waseditedbyuser-set
    'window.matchMedia is not a function', // jest library error - https://github.com/ant-design/ant-design/issues/24906
    `undefined is not an object (evaluating 'r.id')`, // can be safely ignored. Issue with safari and webkit - https://stackoverflow.com/questions/74197049/exception-caught-in-sentry-error-hidden-in-ywebkit-masked-url
  ],
  beforeSend (event, hint) {
    if (process.env.NODE_ENV !== 'production') return null
    if (hint.originalException === 'Timeout') return null
    return event
  }
})

window.mongoId = data => {
  return data && (data._id || data.id)
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
