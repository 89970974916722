import { STATES as STATES_DE } from './germany'
import { STATES as STATES_UK } from './unitedKingdom'
import { STATES as STATES_US } from './unitedStates'
import { STATES as STATES_FR } from './france'
import { STATES as STATES_BR } from './brazil'
import { STATES as STATES_PH } from './philippines'
import { STATES as STATES_PK } from './pakistan'
import { STATES as STATES_CA } from './canada'

const STATES_OTHERS = [{ name: 'Not Applicable', code: 'NA' }]

export const STATES = {
  DE: STATES_DE,
  GB: STATES_UK,
  US: STATES_US,
  FR: STATES_FR,
  BR: STATES_BR,
  PH: STATES_PH,
  PK: STATES_PK,
  CA: STATES_CA,
  others: STATES_OTHERS
}