export const Prefixed_US_CURRENCY = 'US$'

export const CURRENCY_PREFIXES = {
  GBP: '£',
  USD: Prefixed_US_CURRENCY,
  CAD: 'C$',
  AUD: 'A$',
  BDT: '৳',
  IDR: 'Rp',
  CHF: 'SFr.',
  DKK: 'kr',
  HRK: 'kn',
  GEL: '₾',
  HUF: 'ft',
  NOK: 'kr',
  PLN: 'zł',
  RUB: '₽',
  RON: 'lei',
  TRY: '₺',
  UAH: '₴',
  AED: 'د.إ',
  ILS: '₪',
  KES: 'Ksh',
  MAD: '.د.م',
  NGN: '₦',
  ZAR: 'R',
  BRL: 'R$',
  CLP: '$',
  COP: '$',
  PEN: 'S/.',
  MXN: '$',
  JPY: '¥',
  MYR: 'RM',
  NZD: '$',
  PHP: '₱',
  PKR: 'Rs',
  SGD: '$',
  KRW: '₩',
  LKR: 'Rs',
  THB: '฿',
  EUR: '€',
  VND: '₫',
  CNY: '¥',
  INR: '₹',
  SEK: 'kr',
  BGN: 'лв',
  EGP: 'E£',
  GHS: 'GH₵',
  XOF: 'CFA',
  ETB: 'Br',
  GNF: 'Fr',
  AR: '$',
  GTQ: 'Q'
}

export const CURRENCY_SUFFIXES = {}
