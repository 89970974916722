import styled from 'styled-components'
import { Modal } from 'antd'

export const AntModal = styled(Modal)`
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: unset;

  .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: red;
  }

  .ant-modal-body {
    padding: 2.5rem;
  }

  @media screen and (min-width: 600px) and (max-width: 1200px) {
    padding: 2rem;

    .ant-modal-body {
      padding: 2rem;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 1.5rem;

    .ant-modal-body {
      padding: 1.5rem;
    }
  }
`
