import { combineReducers } from 'redux'
import authenticationReducer from './authentication'
import surveyReducer from './surveys'
import userReducer from './users'
import themeReducer from './theme'

const reducer = combineReducers({
  authentication: authenticationReducer,
  survey: surveyReducer,
  user: userReducer,
  theme: themeReducer
})

export default reducer
