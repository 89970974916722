export const STATES = [
  {
    name: 'Auvergne-Rhône-Alpes',
    code: 'FR-ARA'
  },
  {
    name: 'Bourgogne-Franche-Comté',
    code: 'FR-BFC'
  },
  {
    name: 'Bretagne',
    code: 'FR-BRE'
  },
  {
    name: 'Centre-Val de Loire',
    code: 'FR-CVL'
  },
  {
    name: 'Corse',
    code: 'FR-COR'
  },
  {
    name: 'Grand Est',
    code: 'FR-GES'
  },
  {
    name: 'Hauts-de-France',
    code: 'FR-HDF'
  },
  {
    name: 'Île-de-France',
    code: 'FR-IDF'
  },
  {
    name: 'Normandie',
    code: 'FR-NOR'
  },
  {
    name: 'Nouvelle-Aquitaine',
    code: 'FR-NAQ'
  },
  {
    name: 'Occitanie',
    code: 'FR-OCC'
  },
  {
    name: 'Pays de la Loire',
    code: 'FR-PDL'
  },
  {
    name: "Provence-Alpes-Cote d'Azur",
    code: 'FR-PAC'
  },
  {
    name: 'Clipperton',
    code: 'FR-CP'
  },
  {
    name: 'Guadeloupe',
    code: 'FR-GP'
  },
  {
    name: 'Guyane',
    code: 'FR-GF'
  },
  {
    name: 'Martinique',
    code: 'FR-MQ'
  },
  {
    name: 'Mayotte',
    code: 'FR-YT'
  },
  {
    name: 'Novelle-Calédonie',
    code: 'FR-NC'
  },
  {
    name: 'Polynésie',
    code: 'FR-PF'
  },
  {
    name: 'Saint-Pierre-et-Miquelon',
    code: 'FR-PM'
  },
  {
    name: 'Saint Barthélemy',
    code: 'FR-BL'
  },
  {
    name: 'Saint Martin',
    code: 'FR-MF'
  },
  {
    name: 'Réunion',
    code: 'FR-RE'
  },
  {
    name: 'Terres Australes Françaises',
    code: 'FR-TF'
  },
  {
    name: 'Wallis-et-Futuna',
    code: 'FR-WF'
  }
]
