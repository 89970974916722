import { useDispatch } from 'react-redux'
import {
  setAdminView,
  setAuthenticatedUser,
  removeAuthenticatedUser,
  removeImpersonatedUser,
  setImpersonatedUser
} from '@/store/authentication'

const useAuthentication = () => {
  const dispatch = useDispatch()

  const methods = {
    setAdminView: isAdminView => {
      dispatch(setAdminView(isAdminView))
    },
    setUserToken: token => {
      dispatch(setAuthenticatedUser(token))
    },
    setImpersonatorToken: token => {
      dispatch(setImpersonatedUser(token))
    },
    remove: () => {
      dispatch(setAdminView(false))
      dispatch(removeAuthenticatedUser())
      dispatch(removeImpersonatedUser())
    }
  }

  return methods
}

export default useAuthentication
