import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const SectionMain = styled.main`
  max-width: 25rem;

  h1 {
    color: #151723;
    font-size: 4rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  h2 {
    color: #151723;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  p {
    color: #999fa5;

    a {
      font-weight: 400;
    }
  }

  a {
    font-weight: 700;
  }
`
