export const STATES = [
  { name: 'Baden-Württemberg', code: 'DE-BW' },
  { name: 'Bayern', code: 'DE-BY' },
  { name: 'Berlin', code: 'DE-BE' },
  { name: 'Brandenburg', code: 'DE-BB' },
  { name: 'Bremen', code: 'DE-HB' },
  { name: 'Hamburg', code: 'DE-HH' },
  { name: 'Hessen', code: 'DE-HE' },
  { name: 'Mecklenburg-Vorpommern', code: 'DE-MV' },
  { name: 'Niedersachsen', code: 'DE-NI' },
  { name: 'Nordrhein-Westfalen', code: 'DE-NW' },
  { name: 'Rheinland-Pfalz', code: 'DE-RP' },
  { name: 'Saarland', code: 'DE-SL' },
  { name: 'Sachsen', code: 'DE-SN' },
  { name: 'Sachsen-Anhalt', code: 'DE-ST' },
  { name: 'Schleswig-Holstein', code: 'DE-SH' },
  { name: 'Thüringen', code: 'DE-TH' }
]
