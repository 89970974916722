import { useTranslation } from 'react-i18next'
import { LANGUAGE_DIRECTION } from '@/utils/constants'

const useDirection = () => {
  const { i18n } = useTranslation()
  const language = i18n.language
  
  if (language && LANGUAGE_DIRECTION[language] === 'rtl') {
    return ['rtl']
  }

  return ['ltr']
}

export default useDirection
