import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ModalWithContent from '@/components/ModalWithContent'
import useAuthentication from '@/hooks/useAuthentication'

const ModalRedirect = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const authentication = useAuthentication()
  const [error, setError] = useState()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const onForceTasterOut = e => {
      setError(e.detail.error)
    }

    window.addEventListener('forceTasterOut', onForceTasterOut)

    return () => {
      window.removeEventListener('forceTasterOut', onForceTasterOut)
    }
  }, [])

  const { title, content } = useMemo(() => {
    if (error) {
      setVisible(true)

      switch (error) {
        case 'token.expired':
          return {
            title: t('components.forceTasterOut.tokenExpired'),
            content: t('components.forceTasterOut.description')
          }

        case 'user.deleted':
          return {
            title: t('components.forceTasterOut.userDeleted'),
            content: t('components.forceTasterOut.description')
          }

        default:
          return {
            title: t('components.forceTasterOut.defaultError'),
            content: t('components.forceTasterOut.description')
          }
      }
    }

    return {
      title: '',
      content: ''
    }
  }, [error])

  const onOk = () => {
    authentication.remove()
    history.push('/')
    
    setVisible(false)
  }

  return (
    <ModalWithContent
      visible={visible}
      setVisible={setVisible}
      title={title}
      content={content}
      okText={t('pages.verifyEmail.goToHome')}
      onOk={onOk}
    />
  )
}

export default ModalRedirect
