import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'
import cache from '@/utils/cache'
import { usersnap } from '@/utils/tools'

let cacheAuthenticatedUser = null
let cacheImpersonatedUser = null

const checkCacheAuthenticatedUser = () => {
  try {
    const token = cache.getItem('flavorwiki-taster-token')
    cacheAuthenticatedUser = token ? jwt_decode(token) : null
  } catch (error) {
    console.error(error)
  }
}

const checkCacheImpersonatedUser = () => {
  try {
    const token = cache.getItem('flavorwiki-impersonator-token')
    cacheImpersonatedUser = token ? jwt_decode(token) : null
  } catch (error) {
    console.error(error)
  }
}

checkCacheAuthenticatedUser()
checkCacheImpersonatedUser()

export const slice = createSlice({
  name: 'authentication',
  initialState: {
    isAdminView: false,
    authenticatedUser: cacheAuthenticatedUser,
    impersonatedUser: cacheImpersonatedUser
  },
  reducers: {
    setAdminView: (state, action) => {
      state.isAdminView = action.payload
    },
    setAuthenticatedUser: (state, action) => {
      cache.setItem('flavorwiki-taster-token', action.payload)

      const authenticatedUser = jwt_decode(action.payload)
      state.authenticatedUser = authenticatedUser

      if (authenticatedUser && authenticatedUser.id) {
        usersnap.add(authenticatedUser)
      }
    },
    removeAuthenticatedUser: (state, action) => {
      cache.removeItem('flavorwiki-taster-token')
      state.authenticatedUser = null

      usersnap.remove()
    },
    setImpersonatedUser: (state, action) => {
      cache.setItem('flavorwiki-taster-token', action.payload.token)
      state.impersonatedUser = action.payload.impersonator

      cache.setItem('flavorwiki-impersonator-token', action.payload.authToken)
      state.authenticatedUser = action.payload.impersonated
    },
    removeImpersonatedUser: (state, action) => {
      cache.removeItem('flavorwiki-impersonator-token')
      state.impersonatedUser = null
    }
  }
})

export const {
  setAdminView,
  setAuthenticatedUser,
  removeAuthenticatedUser,
  setImpersonatedUser,
  removeImpersonatedUser
} = slice.actions

export default slice.reducer
