export const STATES = [
  {
    name: 'Abra',
    code: 'PH-ABR'
  },
  {
    name: 'Agusan del Norte',
    code: 'PH-AGN'
  },
  {
    name: 'Agusan del Sur',
    code: 'PH-AGS'
  },
  {
    name: 'Aklan',
    code: 'PH-AKL'
  },
  {
    name: 'Albay',
    code: 'PH-ALB'
  },
  {
    name: 'Antique',
    code: 'PH-ANT'
  },
  {
    name: 'Apayao',
    code: 'PH-APA'
  },
  {
    name: 'Aurora',
    code: 'PH-AUR'
  },
  {
    name: 'Basilan',
    code: 'PH-BAS'
  },
  {
    name: 'Bataan',
    code: 'PH-BAN'
  },
  {
    name: 'Batanes',
    code: 'PH-BTN'
  },
  {
    name: 'Batangas',
    code: 'PH-BTG'
  },
  {
    name: 'Benguet',
    code: 'PH-BEN'
  },
  {
    name: 'Biliran',
    code: 'PH-BIL'
  },
  {
    name: 'Bohol',
    code: 'PH-BOH'
  },
  {
    name: 'Bukidnon',
    code: 'PH-BUK'
  },
  {
    name: 'Bulacan',
    code: 'PH-BUL'
  },
  {
    name: 'Cagayan',
    code: 'PH-CAG'
  },
  {
    name: 'Camarines Norte',
    code: 'PH-CAN'
  },
  {
    name: 'Camarines Sur',
    code: 'PH-CAS'
  },
  {
    name: 'Camiguin',
    code: 'PH-CAM'
  },
  {
    name: 'Capiz',
    code: 'PH-CAP'
  },
  {
    name: 'Catanduanes',
    code: 'PH-CAT'
  },
  {
    name: 'Cavite',
    code: 'PH-CAV'
  },
  {
    name: 'Cebu',
    code: 'PH-CEB'
  },
  {
    name: 'Compostela',
    code: 'PH-COM'
  },
  {
    name: 'Cotabato',
    code: 'PH-NCO'
  },
  {
    name: 'Davao del Norte',
    code: 'PH-DAV'
  },
  {
    name: 'Davao del Sur',
    code: 'PH-DAS'
  },
  {
    name: 'Davao Occidental',
    code: 'PH-DVO'
  },
  {
    name: 'Davao Oriental',
    code: 'PH-DAO'
  },
  {
    name: 'Dinagat Islands',
    code: 'PH-DIN'
  },
  {
    name: 'Eastern Samar',
    code: 'PH-EAS'
  },
  {
    name: 'Guimaras',
    code: 'PH-GUI'
  },
  {
    name: 'Ifugao',
    code: 'PH-IFU'
  },
  {
    name: 'Ilocos Norte',
    code: 'PH-ILN'
  },
  {
    name: 'Ilocos Sur',
    code: 'PH-ILS'
  },
  {
    name: 'Iloilo',
    code: 'PH-ILI'
  },
  {
    name: 'Isabela',
    code: 'PH-ISA'
  },
  {
    name: 'Kalinga',
    code: 'PH-KAL'
  },
  {
    name: 'La Union',
    code: 'PH-LUN'
  },
  {
    name: 'Laguna',
    code: 'PH-LAG'
  },
  {
    name: 'Lanao del Norte',
    code: 'PH-LAN'
  },
  {
    name: 'Lanao del Sur',
    code: 'PH-LAS'
  },
  {
    name: 'Leyte',
    code: 'PH-LEY'
  },
  {
    name: 'Maguindanao',
    code: 'PH-MAG'
  },
  {
    name: 'Masbate',
    code: 'PH-MAS'
  },
  {
    name: 'Metro Manila',
    code: 'PH-00'
  },
  {
    name: 'Mindoro Occidental',
    code: 'PH-MDC'
  },
  {
    name: 'Mindoro Oriental',
    code: 'PH-MDR'
  },
  {
    name: 'Misamis Occidental',
    code: 'PH-MSC'
  },
  {
    name: 'Misamis Oriental',
    code: 'PH-MSR'
  },
  {
    name: 'Mountain Province',
    code: 'PH-MOU'
  },
  {
    name: 'Negros Occidental',
    code: 'PH-NEC'
  },
  {
    name: 'Negros Oriental',
    code: 'PH-NER'
  },
  {
    name: 'Northern Samar',
    code: 'PH-NSA'
  },
  {
    name: 'Nueva Ecija',
    code: 'PH-NUE'
  },
  {
    name: 'Nueva Vizcaya',
    code: 'PH-NUV'
  },
  {
    name: 'Palawan',
    code: 'PH-PLW'
  },
  {
    name: 'Pampanga',
    code: 'PH-PAM'
  },
  {
    name: 'Pangasinan',
    code: 'PH-PAN'
  },
  {
    name: 'Quezon',
    code: 'PH-QUE'
  },
  {
    name: 'Quirino',
    code: 'PH-QUI'
  },
  {
    name: 'Rizal',
    code: 'PH-RIZ'
  },
  {
    name: 'Romblon',
    code: 'PH-ROM'
  },
  {
    name: 'Samar',
    code: 'PH-WSA'
  },
  {
    name: 'Sarangani',
    code: 'PH-SAR'
  },
  {
    name: 'Siquijor',
    code: 'PH-SIG'
  },
  {
    name: 'Sorsogon',
    code: 'PH-SOR'
  },
  {
    name: 'Southern Leyte',
    code: 'PH-SLE'
  },
  {
    name: 'Sultan Kudarat',
    code: 'PH-AUK'
  },
  {
    name: 'Sulu',
    code: 'PH-SLU'
  },
  {
    name: 'Surigao del Norte',
    code: 'PH-SUN'
  },
  {
    name: 'Surigao del Sur',
    code: 'PH-SUR'
  },
  {
    name: 'Tarlac',
    code: 'PH-TAR'
  },
  {
    name: 'Tawi-Tawi',
    code: 'PH-TAW'
  },
  {
    name: 'Zambales',
    code: 'PH-ZMB'
  },
  {
    name: 'Zamboanga del Norte',
    code: 'PH-ZAN'
  },
  {
    name: 'Zamboanga del Sur',
    code: 'PH-ZAS'
  },
  {
    name: 'Zamboanga Sibugay',
    code: 'PH-ZSI'
  }
]
