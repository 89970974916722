export const STATES = [
  {
    name: 'Alberta',
    code: 'CA-AB'
  },
  {
    name: 'British Columbia',
    code: 'CA-BC'
  },
  {
    name: 'Manitoba',
    code: 'CA-MB'
  },
  {
    name: 'New Brunswick',
    code: 'CA-NB'
  },
  {
    name: 'Newfoundland and Labrador',
    code: 'CA-NL'
  },
  {
    name: 'Northwest Territories',
    code: 'CA-NT'
  },
  {
    name: 'Nova Scotia',
    code: 'CA-NS'
  },
  {
    name: 'Nunavut',
    code: 'CA-NU'
  },
  {
    name: 'Ontario',
    code: 'CA-ON'
  },
  {
    name: 'Prince Edward Island',
    code: 'CA-PE'
  },
  {
    name: 'Quebec',
    code: 'CA-QC'
  },
  {
    name: 'Saskatchewan',
    code: 'CA-SK'
  },
  {
    name: 'Yukon Territory',
    code: 'CA-YT'
  }
]
